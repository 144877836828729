<template>
  <!-- 结算管理 - 开票管理 - 新增/编辑 -->
  <div class="pageContol listWrap templateList faceStudentstatistics">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="completez(1)">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">开票管理 - 添加/编辑</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="160px"
              :disabled="$route.query.applicationComp != '10'"
              class="demo-ruleForm basicData"
              ><el-form-item label="开票单位" prop="openCompany">
                <el-select
                  clearable
                  placeholder="请选择开票单位"
                  size="small"
                  v-model="ruleForm.openCompany"
                >
                  <el-option
                    size="small"
                    v-for="item in openCompanyList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="票据类型" prop="invoiceType">
                <el-select
                  clearable
                  placeholder="请选择票据类型"
                  size="small"
                  v-model="ruleForm.invoiceType"
                >
                  <el-option
                    size="small"
                    v-for="item in invoice_type"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="$route.query.applicationComp === '10'&&(!$route.query.settlementInfoData||$route.query.stu=='billState')" label="有无结算单" prop="hasBill">
                <el-radio-group v-model="ruleForm.hasBill">
                  <el-radio :label="true">有结算单</el-radio>
                  <el-radio :label="false">无结算单</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="收票单位" prop="closeCompanyId" style="position:relative;">
                <el-select
                  size="small"
                  v-model="ruleForm.closeCompanyId"
                  remote
                  :remote-method="getCompanyList"
                  filterable
                  allow-create
                  clearable
                  placeholder="输入关键字"
                  @change="bindChange"
                >
                  <el-option
                    v-for="item in CompanyList"
                    :key="item.closeCompanyId"
                    :label="item.closeCompany"
                    :value="item.closeCompanyId"
                  ></el-option>
                </el-select>
                <el-button type="text" v-if="ruleForm.closeCompanyId" :disabled="false" style="position:absolute;right: -50px;top: 0;padding: 10px;" @click="copyPublic(ruleForm.closeCompany)">复制</el-button>
              </el-form-item>
              <el-form-item label="税号" prop="taxNo">
                <el-input
                  clearable
                  placeholder="请输入税号"
                  size="small"
                  v-model="ruleForm.taxNo"
                ></el-input>
                <el-button type="text" v-if="ruleForm.taxNo" :disabled="false" style="position:absolute;right: -50px;top: 0;padding: 10px;" @click="copyPublic(ruleForm.taxNo)">复制</el-button>
              </el-form-item>
              <el-form-item
                label="开户行及账号"
                :rules="[
                  {
                    required: ruleForm.invoiceType == 10 ? true : false,
                    message: '请输入开户行及账号',
                    trigger: 'blur',
                  },
                ]"
                prop="accountNumber"
              >
                <el-input
                  clearable
                  placeholder="请输入开户行及账号"
                  size="small"
                  v-model="ruleForm.accountNumber"
                ></el-input>
                <el-button type="text" v-if="ruleForm.accountNumber" :disabled="false" style="position:absolute;right: -50px;top: 0;padding: 10px;" @click="copyPublic(ruleForm.accountNumber)">复制</el-button>
              </el-form-item>
              <el-form-item label="开票金额" prop="invoiceMoney">
                <el-input
                  clearable
                  placeholder="请输入开票金额"
                  size="small"
                  :disabled="stu == 'settlement'"
                  v-model="ruleForm.invoiceMoney"
                  @keyup.native="
                    ruleForm.invoiceMoney = zF.oninput2(
                      ruleForm.invoiceMoney,
                      2
                    )
                  "
                ></el-input>
              </el-form-item>
              <el-form-item
                label="地址、电话"
                :rules="[
                  {
                    required:
                      ruleForm.invoiceType == 10 || ruleForm.invoiceType == 30
                        ? true
                        : false,
                    message: '请输入地址、电话',
                    trigger: 'blur',
                  },
                ]"
                prop="addressMobile"
              >
                <el-input
                  clearable
                  placeholder="请输入地址、电话"
                  size="small"
                  v-model="ruleForm.addressMobile"
                ></el-input>
                <el-button type="text" v-if="ruleForm.addressMobile" :disabled="false" style="position:absolute;right: -50px;top: 0;padding: 10px;" @click="copyPublic(ruleForm.addressMobile)">复制</el-button>
              </el-form-item>
              <el-form-item
                label="邮箱"
                prop="email"
                :rules="[
                  {
                    required: ruleForm.invoiceType == 40 ? true : false,
                    message: '请输入邮箱',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  clearable
                  placeholder="请输入邮箱"
                  size="small"
                  v-model="ruleForm.email"
                ></el-input>
                <el-button type="text" v-if="ruleForm.email" :disabled="false" style="position:absolute;right: -50px;top: 0;padding: 10px;" @click="copyPublic(ruleForm.email)">复制</el-button>
              </el-form-item>

              <el-form-item label="开票日期" prop="invoiceDate">
                <el-date-picker
                  type="date"
                  clearable
                  size="small"
                  placeholder="选择开票日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="ruleForm.invoiceDate"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                label="开票说明"
                :rules="[
                  {
                    required:
                      ruleForm.invoiceType == 10 || ruleForm.invoiceType == 30
                        ? true
                        : false,
                    message: '请输入开票说明',
                    trigger: 'blur',
                  },
                ]"
                prop="invoiceExplain"
              >
                <el-input
                  type="textarea"
                  v-model="ruleForm.invoiceExplain"
                ></el-input>
              </el-form-item>
              <el-form-item label="备注">
                <el-input type="textarea" v-model="ruleForm.remark"></el-input>
              </el-form-item>
            </el-form>
            <el-divider></el-divider>
            <div v-if="$route.query.applicationComp != '20'">
              <el-row style="margin-bottom: 10px">
                <el-col :span="12"
                  ><div
                    class="grid-content"
                    style="text-align: left; font-weight: 600; font-size: 17px"
                  >
                    结算单列表
                  </div></el-col
                >
                <el-col :span="12"
                  ><div
                    class="grid-content"
                    style="text-align: right"
                    v-if="
                      stu != 'settlement' &&
                      $route.query.applicationComp == '10'
                    "
                  >
                    <el-button
                      type="primary"
                      size="small"
                      class="bgc-bv"
                      icon="el-icon-plus"
                      @click="addInfo"
                      >新增</el-button
                    >
                  </div></el-col
                >
              </el-row>
              <el-table
                ref="multipleTable"
                :data="ruleForm.billNodeList"
                min-height="200px"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
                ><el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="80px"
                /><el-table-column
                  label="结算单编号"
                  align="left"
                  show-overflow-tooltip
                  prop="billNo"
                  minWidth="100"
                />
                <el-table-column
                  label="已开票金额"
                  align="left"
                  show-overflow-tooltip
                  prop="alreadyMoney"
                  minWidth="100"
                />
                <el-table-column
                  label="开票状态"
                  align="left"
                  show-overflow-tooltip
                  v-if="$route.query.applicationComp === '10'&&(!$route.query.settlementInfoData||$route.query.stu=='billState')"
                  prop="isInvoice"
                  minWidth="100"
                >
                <template slot-scope="{row}">
                  <el-select
                    size="small"
                    v-model="row.isInvoice"
                    >
                    <el-option label="全部开票" :value="1" />
                    <el-option label="部分开票" :value="2" />
                  </el-select>
                </template>
                </el-table-column>
                <el-table-column
                  label="本次开票金额"
                  align="left"
                  show-overflow-tooltip
                  prop="currentMoney"
                  minWidth="100"
                  ><template slot-scope="scope">
                    <!-- <el-input
                      v-if="
                        $route.query.applicationComp == '10' &&
                        stu != 'settlement'
                      "
                      size="small"
                      v-model="scope.row.currentMoney"
                      placeholder="请输入"
                      @keyup.native="
                        scope.row.currentMoney = zF.oninput2(
                          scope.row.currentMoney,
                          2
                        )
                      "
                      @change="currentMoneyChange"
                    ></el-input> -->
                    <span>{{ scope.row.currentMoney }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  align="center"
                  width="200px"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      :disabled="
                        $route.query.applicationComp != '10' ||
                        stu == 'settlement'
                      "
                      @click="deleteInvoicing(scope.row, scope.$index)"
                      >删除</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      v-if="$route.query.applicationComp === '10'&&(!$route.query.settlementInfoData||$route.query.stu=='billState')"
                      style="padding: 0 5px"
                      @click="bkpList(scope.row, scope.$index)"
                      >开票明细</el-button
                    >
                  </template>
                </el-table-column>
                <Empty slot="empty" />
              </el-table>
            </div>
            <div v-else>
              <el-row style="margin-bottom: 10px">
                <el-col :span="12"
                  ><div
                    class="grid-content"
                    style="text-align: left; font-weight: 600; font-size: 17px"
                  >
                    订单列表
                  </div></el-col
                >
              </el-row>
              <el-table
                ref="multipleTable"
                :data="orderList"
                size="small"
                tooltip-effect="dark"
                row-key="orderId"
                style="width: 100%"
                @selection-change="checkListData"
                :header-cell-style="tableHeader"
                stripe
              >
                <el-table-column
                  label="序号"
                  align="left"
                  type="index"
                  :index="indexMethod"
                  fixed
                  width="60px"
                />
                <el-table-column
                  label="订单编号"
                  align="left"
                  show-overflow-tooltip
                  prop="orderSn"
                />
                <el-table-column
                  label="支付订单编号"
                  align="left"
                  show-overflow-tooltip
                  prop="orderPaymentNumber"
                />
                <el-table-column
                  label="支付类型"
                  align="left"
                  show-overflow-tooltip
                  prop="paymentMethod"
                >
                  <template slot-scope="scope">
                    {{
                      $setDictionary("PAYMENT_METHOD", scope.row.paymentMethod)
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="订单总额"
                  align="left"
                  show-overflow-tooltip
                  prop="paymentMoney"
                />

                <el-table-column
                  label="支付时间"
                  align="left"
                  prop="payTime"
                  width="150"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    {{ scope.row.payTime | moment }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  align="center"
                  width="100px"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="lookInvoice(scope.row.orderId)"
                      >查看</el-button
                    >
                  </template>
                </el-table-column>
                <Empty slot="empty" />
              </el-table>
            </div>
            <el-divider></el-divider>
            <div class="btn-box">
              <el-button
                type="primary"
                @click="completez(1)"
                size="small"
                class="bgc-bv"
                >取 消</el-button
              >
              <el-button
                type="primary"
                v-if="$route.query.applicationComp == '10'"
                @click="completez(2)"
                size="small"
                class="bgc-bv"
                >确 定</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增 - 结算单列表 - loading -->
    <el-dialog
      title="选择结算单"
      :visible.sync="addListDataLoadin"
      width="70%"
      top="2%"
      center
      :before-close="addListDataLoadinClose"
      ><div class="operationControl searchboxfl">
        <div class="searchbox">
          <div title="所属机构" class="searchboxItem ci-full">
            <span class="itemLabel">所属机构:</span>
            <el-select
              size="small"
              v-model="retrievalDatas.compId"
              remote
              :remote-method="getCompanyList1"
              filterable
              clearable
              placeholder="请至少输入两个字搜索"
              ><el-option
                v-for="item in CompanyList1"
                :key="item.compId"
                :label="item.compName"
                :value="item.compId"
              ></el-option>
            </el-select>
          </div>
          <div title="付款机构名称" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width: 8rem">付款机构名称:</span>
            <el-input
              v-model="retrievalDatas.payerName"
              clearable
              placeholder="请输入付款机构名称"
              size="small"
            ></el-input>
          </div>
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="'460px'"
            size="small"
            tooltip-effect="dark"
            row-key="billId"
            style="width: 100%"
            @selection-change="checkListData"
            :header-cell-style="tableHeader"
            stripe
          >
            <el-table-column
              type="selection"
              width="50px"
              align="center"
              :reserve-selection="true"
              :selectable="handleDisable"
            ></el-table-column>
            <el-table-column
              label="序号"
              align="left"
              type="index"
              :index="indexMethod"
              width="60px"
            />
            <el-table-column
              label="结算编号"
              align="left"
              show-overflow-tooltip
              prop="billNo"
              min-width="100"
            />
            <el-table-column
              label="平台结算金额"
              align="left"
              show-overflow-tooltip
              prop="settlementAmount"
              min-width="60"
            />
            <el-table-column
              label="所属机构"
              align="left"
              show-overflow-tooltip
              prop="compName"
              min-width="100"
            />
            <el-table-column
              label="付款机构"
              align="left"
              show-overflow-tooltip
              prop="payerName"
              min-width="100"
            />
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      <div class="BatchInvoicingManagementAddEdit">
        <div class="BatchBox">
          <div class="BatchBoxFirst">
            <p>已选结算单</p>
            <p>（{{ backArr.length }}）</p>
          </div>
          <div class="BatchBoxSecond ovy-a">
            <div class="BatchforBox">
              <div
                v-for="(item, index) in backArr"
                :key="index"
                class="BatchforFlex"
              >
                <div class="BatchImgBox" @click="deleteImg(item)">
                  <img
                    :src="require('@/assets/close_.png')"
                    alt=""
                    @mouseover="transToWhite(index, $event)"
                    @mouseleave="transToBlack(index, $event)"
                  />
                </div>
                <span style="padding-left: 5px">{{ item.billNo }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="addListDataLoadinClose">取 消</el-button>
        <el-button @click="addOk" class="bgc-bv" type="primary"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 查看订单信息 - 弹框 -->
    <el-dialog title="订单信息" :visible.sync="invoicedialog" top="10%"
      ><div class="ovy-a">
        <el-descriptions title="订单详情">
          <el-descriptions-item label="订单编号:">{{
            orderInfo.orderSn
          }}</el-descriptions-item>
          <el-descriptions-item label="订单总额:">{{
            orderInfo.paymentMoney
          }}</el-descriptions-item>
          <el-descriptions-item label="总数量:">{{
            orderInfo.totalCount
          }}</el-descriptions-item>
          <el-descriptions-item label="总课时:">{{
            orderInfo.lessonNum
          }}</el-descriptions-item>
          <el-descriptions-item label="支付时间:">{{
            orderInfo.payTime | moment
          }}</el-descriptions-item>
          <el-descriptions-item label="创建时间:">{{
            orderInfo.createTime | moment
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="课程信息" style="margin-top: 15px">
          <el-descriptions-item label="课程名称:">{{
            orderInfo.courseInfoList
              ? orderInfo.courseInfoList[0].productName
              : ""
          }}</el-descriptions-item>
          <el-descriptions-item label="课程单价:"
            >{{
              orderInfo.courseInfoList
                ? orderInfo.courseInfoList[0].productPrice
                : ""
            }}元</el-descriptions-item
          >
          <el-descriptions-item label="总数量:">{{
            orderInfo.courseInfoList
              ? orderInfo.courseInfoList[0].productCnt
              : ""
          }}</el-descriptions-item>
          <el-descriptions-item label="金额:"
            >{{
              orderInfo.courseInfoList
                ? orderInfo.courseInfoList[0].totalPrice
                : ""
            }}元</el-descriptions-item
          >
          <el-descriptions-item label="订单状态:">{{
            $setDictionary(
              "ORDER_STATUS",
              orderInfo.courseInfoList
                ? orderInfo.courseInfoList[0].orderStatus
                : ""
            )
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="orderInfoNo">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 开票明细 -->
    <el-dialog
      title="开票明细"
      :visible.sync="bgkpVisible"
      v-if="bgkpVisible"
      :before-close="doClose"
      width="80%"
    >
      <el-table
        ref="kpData"
        :data="kpData"
        size="small"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="tableHeader"
        stripe
        show-summary
      >
        <el-table-column label="序号" align="center" type="index" />
        <el-table-column
          label="班级编码"
          align="left"
          show-overflow-tooltip
          prop="projectCode"
        />
        <el-table-column
          label="班级名称"
          align="left"
          show-overflow-tooltip
          prop="projectName"
          width="220px"
        />
        <el-table-column
          label="应收金额"
          align="right"
          show-overflow-tooltip
          prop="amountReceivable"
        />
        <el-table-column
          label="实收金额"
          align="right"
          show-overflow-tooltip
          prop="actualReceivedFee"
        />
        <el-table-column
          label="已开票金额"
          align="right"
          show-overflow-tooltip
          prop="invoiceFeeDone"
        />
        <el-table-column label="开票金额" align="right" prop="invoiceFee">
          <template slot-scope="scope">
            <el-input
              size="small"
              class="txtRight"
              v-model="scope.row.invoiceFee"
              @keyup.native="scope.row.invoiceFee = listenIn(scope.row)"
              clearable
              placeholder="请输入"
            ></el-input>
          </template>
        </el-table-column>
        <Empty slot="empty" />
      </el-table>
      <div
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: flex-end"
      >
        <el-button
          type="primary"
          size="small"
          @click="kpDoOk"
          class="bgc-bv"
          >确定</el-button
        >
        <el-button @click="doClose" size="small">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "invoicingManagementAddEdit",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 开票id
      id: "",
      //开票stu
      stu: "",
      // 基础数据集
      ruleForm: {
        // 开票单位
        openCompany: "北京晟融信息技术有限公司",
        // 票据类型
        invoiceType: "40",
        // 有无结算单
        hasBill: true,
        // 收票单位id
        closeCompanyId: "",
        // 收票单位
        closeCompany: "",
        // 税号
        taxNo: "",
        // 开户行及账号
        accountNumber: "",
        // 开票金额
        invoiceMoney: 0,
        // 地址、电话
        addressMobile: "",
        // 邮箱
        email: "",
        // 开票日期
        invoiceDate: "",
        // 开票说明
        invoiceExplain: "",
        // 备注
        remark: "",
        // 结算单列表 - 数据
        billNodeList: [],
      },
      // 开票单位 - 下拉数据
      openCompanyList: [],
      // 收票单位 - 下拉数据
      CompanyList: [],
      // 票据种类 - 下拉数据
      invoice_type: [],
      // 基础数据集 - 数据校验
      rules: {
        // 开票单位
        name: [{ required: true, message: "请输入开票单位", trigger: "blur" }],
        // 票据类型
        invoiceType: [
          { required: true, message: "请输入票据类型", trigger: "change" },
        ],
        // 票据类型
        hasBill: [
          { required: true, message: "请选择有无结算单", trigger: "change" },
        ],
        // 收票单位
        closeCompanyId: [
          { required: true, message: "请输入收票单位", trigger: "blur" },
        ],
        // 税号
        taxNo: [{ required: true, message: "请输入税号", trigger: "blur" }],
        // 开票金额
        invoiceMoney: [
          { required: true, message: "请输入开票金额", trigger: "blur" },
        ],
        // 地址、电话
        addressMobile: [
          { required: true, message: "请输入 地址、电话", trigger: "blur" },
        ],
        // 开票日期
        invoiceDate: [
          { required: true, message: "请选择开票日期", trigger: "change" },
        ],
      },
      // 新增 - 结算单列表 - 数据 - loading
      addListDataLoadin: false,
      // 新增 - 结算单列表 - 检索数据
      retrievalDatas: {
        compId: "", // 培训机构
        payerName: "", // 付款机构
      },
      // 新增 - 所属机构 - 下拉数据
      CompanyList1: [],
      // 新增 - 列表勾选的数据
      multipleTable: [],
      // 新增 - 同步列表勾选的底部数据
      backArr: [],
      orderList: [], //訂單數據
      invoicedialog: false, //訂單查看
      orderInfo: {},
      bgkpVisible: false,//开票弹窗
      kpData: [],//开票列表
      kpIndex: 0,//开票索引
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.stu = this.$route.query.stu;
    this.applicationComp = this.$route.query.applicationComp;
    console.log(this.$route.query.applicationComp);
    this.getAopenCompanyList();
    this.getInvoice_type();
    // 有id调编辑接口
    if (this.$route.query.id) {
      this.getKpData();
    }
    if (this.$route.query.applicationComp == "20") {
      this.lookEdit(this.$route.query.id);
    }
    this.ruleForm.invoiceDate = this.$moment().format("YYYY-MM-DD");
    // 无id判断下是不是结算单列表带过来的新增
    if (this.$route.query.settlementInfoData && JSON.parse(this.$route.query.settlementInfoData).length) {
      if (this.stu == "settlement") {
        let currentMoney = 0;
        let arr = JSON.parse(this.$route.query.settlementInfoData);
        for (let i = 0; i < arr.length; i++) {
          arr[i].projectList.map((el) => {
            currentMoney += Number(el.platformSettlementAmount);
          });
          this.$set(arr[i], "currentMoney", currentMoney);
          this.$set(arr[i], "alreadyMoney", 0);
          this.ruleForm.invoiceMoney = currentMoney;
          // this.$set(arr[i]., "billNo", arr[i].settlementAmount);
        }
        this.ruleForm.billNodeList = [...this.ruleForm.billNodeList, ...arr];

        this.ruleForm.invoiceDate = this.$moment().format("YYYY-MM-DD");
        this.getCompanyJg(this.userJson.compId);
      } else {
        let arr = JSON.parse(this.$route.query.settlementInfoData);
        for (let i = 0; i < arr.length; i++) {
          this.$set(arr[i], "currentMoney", arr[i].settlementAmount);
        }
        this.ruleForm.billNodeList = [...this.ruleForm.billNodeList, ...arr];
        let hash = {};
        this.ruleForm.billNodeList = this.ruleForm.billNodeList.reduce(
          function (preVal, curVal) {
            hash[curVal.billId]
              ? " "
              : (hash[curVal.billId] = true && preVal.push(curVal));
            return preVal;
          },
          []
        );
        this.$set(this.ruleForm, "billNodeList", this.ruleForm.billNodeList);

        this.ruleForm.billNodeList.map(async (el) => {
          if (!el.currentMoney) {
            el.currentMoney = 0;
          }
          this.$set(el,'isInvoice',el.isInvoice||1)
          try {
            el.projectList = await this.projectDetailList(el.billId)
            // 默认开票金额等于应收金额
            el.projectList.map(e=>{
              if(e.invoiceFee===undefined&&e.amountReceivable){
                this.$set(e,'invoiceFee',String(e.amountReceivable))
              }
            })
            // 默认本次开票金额等于所以应收金额之和
            el.currentMoney = el.projectList.reduce((a, b) => {
              a += (isNaN(+b.invoiceFee)?0:+b.invoiceFee);
              return a
            }, 0);
            this.ruleForm.invoiceMoney += Number(el.currentMoney);
          } catch (error) {
            
          }
        });
        this.ruleForm.closeCompanyId = this.ruleForm.billNodeList[0].payerName;
        this.getCompanyName(this.ruleForm.billNodeList[0].payerName);
        this.ruleForm.invoiceDate = this.$moment().format("YYYY-MM-DD");
      }
    }
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  mounted() {},
  methods: {
    // 复制
    copyPublic(value){
      let oInput = document.createElement('input');
      oInput.value = value;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value)
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: '复制成功',
        type: 'success'
      });
      oInput.remove()
    },
    // 获取字典 - 开票单位 - 下拉数据
    getAopenCompanyList() {
      let arr = this.$setDictionary("INVOICE_OPEN_COMPANY", "list");
      for (const key in arr) {
        this.openCompanyList.push({
          label: arr[key],
          value: key,
        });
      }
    },
    // 获取字典 - 票据种类 - 下拉数据
    getInvoice_type() {
      let arr = this.$setDictionary("INVOICE_TYPE", "list");
      for (const key in arr) {
        this.invoice_type.push({
          label: arr[key],
          value: key,
        });
      }
    },
    // 获取数据 - 收票单位
    getCompanyList(query) {
      this.$post("/biz/new/bill/invoice/company/pageList", {
        closeCompany: query,
      })
        .then((res) => {
          if (res.status == 0) {
            this.CompanyList = res.data.list || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    // 回显 - 收票单位
    getCompany(id) {
      this.$post("/biz/new/bill/invoice/company/getById", {
        closeCompanyId: id,
      }).then((res) => {
        this.CompanyList = [res.data];
        this.ruleForm.taxNo = res.data.taxNo;
      });
    },
    // 选中 - 收票单位 - 获取赋值name
    bindChange(val) {
      let obj = {};
      obj = this.CompanyList.find((item) => {
        return item.closeCompanyId === val;
      });
      if (obj != undefined) {
        // 收票名称
        this.ruleForm.closeCompany = obj.closeCompany;
        // 税号
        this.ruleForm.taxNo = obj.taxNo;
        // 开户行及账号
        this.ruleForm.accountNumber = obj.accountNumber;
        // 地址、电话
        this.ruleForm.addressMobile = obj.addressMobile;
        // 邮箱
        this.ruleForm.email = obj.email;
      } else {
        this.ruleForm.closeCompany = val;
      }
    },
    // 初始化获 - 编辑数据
    getKpData() {
      const params = {
        invoiceId: this.$route.query.id,
      };
      this.$post("/biz/new/bill/invoice/callBack", params).then((res) => {
        if (res.status == 0) {
          // 开票单位
          this.ruleForm.openCompany = res.data.openCompany || "";
          // 票据类型
          this.ruleForm.invoiceType = res.data.invoiceType || "";
          // 有无结算单
          this.ruleForm.hasBill = res.data.hasBill;
          // 收票单位id
          this.ruleForm.closeCompanyId = res.data.closeCompanyId || "";
          // 收票单位
          this.ruleForm.closeCompany = res.data.closeCompany || "";
          // 税号
          this.ruleForm.taxNo = res.data.taxNo || "";
          // 开户行及账号
          this.ruleForm.accountNumber = res.data.accountNumber || "";
          // 开票金额
          this.ruleForm.invoiceMoney = res.data.invoiceMoney ?? "";
          // 地址、电话
          this.ruleForm.addressMobile = res.data.addressMobile || "";
          // 邮箱
          this.ruleForm.email = res.data.email || "";
          // 开票日期
          this.ruleForm.invoiceDate =
            res.data.invoiceDate.replaceAll("/", "-") || "";
          // 开票说明
          this.ruleForm.invoiceExplain = res.data.invoiceExplain || "";
          // 备注
          this.ruleForm.remark = res.data.remark || "";
          // 结算单列表 - 数据
      
          this.ruleForm.billNodeList = res.data.billNodeList || [];
          // 回显 - 收票单位
          this.getCompany(res.data.closeCompanyId);
        }
      });
    },
    lookEdit(invoiceId) {
      this.$post("/biz/invoice/order/getList", { invoiceId }).then((res) => {
        this.orderList = res.data || [];
      });
    },
    //查看订单信息
    lookInvoice(orderId) {
      this.$post("/biz/invoice/order/getInfo", { orderId }).then((res) => {
        if (res.status == 0) {
          this.orderInfo = {
            ...res.data,
          };
          this.invoicedialog = true;
        }
      });
    },
    orderInfoNo() {
      this.invoicedialog = false;
      this.orderInfo = {};
    },
    // 新增 - 三个字搜索 - 所属机构
    getCompanyList1(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList1 = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList1 = [];
      }
    },
    // 新增 - 结算单列表
    addInfo() {
      this.addListDataLoadin = true;
    },
    // 新增 - 结算单列表 - 获取
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      // 所属机构
      if (this.retrievalDatas.compId) {
        params.compId = this.retrievalDatas.compId;
      }
      // 所属机构
      if (this.retrievalDatas.payerName) {
        params.payerName = this.retrievalDatas.payerName;
      }
      this.doFetch({
        url: "/biz/new/bill/invoice/billPageList",
        params,
        pageNum,
      });
    },
    // 新增 - 结算单列表 - 勾选列表数据
    checkListData(rowArr) {
      this.multipleTable = rowArr;
      this.backArr = rowArr;
    },
    // 禁用相同id
    handleDisable(row, index) {
      return this.ruleForm.billNodeList.every(e=>e?.billId!==row?.billId)
    },
    // 新增 - 结算单列表 - 鼠标移入关闭样式
    transToWhite(index, e) {
      e.target.src = require("@/assets/error.png");
    },
    // 新增 - 结算单列表 - 鼠标离开关闭样式
    transToBlack(index, e) {
      e.target.src = require("@/assets/close_.png");
    },
    // 新增 - 结算单列表 - 删除底部已选
    deleteImg(itemobj) {
      let newArr = this.backArr.filter((item) => item.billId != itemobj.billId);
      this.backArr = newArr;
      this.multipleTable.find((el, index) => {
        if (el.billId == itemobj.billId) {
          delete this.multipleTable[index];
          this.$refs.multipleTable.toggleRowSelection(el, false);
        }
      });
    },
    // 新增 - 结算单列表 - 关闭
    addListDataLoadinClose() {
      this.$refs.multipleTable.clearSelection();
      this.addListDataLoadin = false;
    },
    // 新增 - 结算单列表 - 确定
    addOk() {
      if (this.multipleTable.length > 0) {
        if (new Set(this.multipleTable.map((t) => t.payerName)).size > 1) {
          this.$message({
            message: "不同付款机构不能申请开票",
            type: "error",
          });
          return;
        }
        let bilStu = false;
        if (this.ruleForm.billNodeList.length > 0) {
          this.multipleTable.map((t) => {
            console.log(t.payerName);
            if (t.payerName != this.ruleForm.billNodeList[0].payerName) {
              console.log(this.ruleForm.billNodeList[0].payerName);
              this.$message({
                message: "不同付款机构不能申请开票",
                type: "error",
              });
              bilStu = true;
            }
          });
        }
        if (bilStu) return;
        for (let i = 0; i < this.multipleTable.length; i++) {
          this.$set(
            this.multipleTable[i],
            "currentMoney",
            this.multipleTable[i].settlementAmount
          );
        }  
        // this.ruleForm.billNodeList = [
        //   ...this.ruleForm.billNodeList,
        //   ...this.multipleTable,
        // ];
      
        this.ruleForm.billNodeList = this.ruleForm.billNodeList.concat(this.multipleTable)
        // this.$set()
        let hash = {};
        this.ruleForm.billNodeList = this.ruleForm.billNodeList.reduce(
          function (preVal, curVal) {
            hash[curVal.billId]
              ? " "
              : (hash[curVal.billId] = true && preVal.push(curVal));
            return preVal;
          },
          []
        );
        // this.$set(this.ruleForm, "billNodeList", this.ruleForm.billNodeList);
        this.$refs.multipleTable.clearSelection();
        this.ruleForm.invoiceMoney = 0;
        this.ruleForm.billNodeList.map(async (el) => {
          this.$set(el,'isInvoice',el.isInvoice||1)
          try {
            el.projectList = await this.projectDetailList(el.billId)
            // 默认开票金额等于应收金额
            el.projectList.map(e=>{
              console.log(e)
              // 应收金额 和 已开票金额 都有的情况下； 开票金额 = 应收金额 - 已开票金额
              if(typeof e.amountReceivable  == 'number' && typeof e.invoiceFeeDone == 'number'){
                e.invoiceFee = (Number(e.amountReceivable)-Number(e.invoiceFeeDone)).toFixed(2);
              } else {
                e.invoiceFee = String(e.amountReceivable);
              }
            })
            // 默认本次开票金额等于所以应收金额之和
            el.currentMoney = el.projectList.reduce((a, b) => {
              a += (isNaN(+b.invoiceFee)?0:+b.invoiceFee);
              return a
            }, 0);
            el.currentMoney = el.currentMoney.toFixed(2);
            this.ruleForm.invoiceMoney = (Number(this.ruleForm.invoiceMoney) + Number(el.currentMoney)).toFixed(2);;
          } catch (error) {
            
          }
        });
        // this.ruleForm.invoiceMoney = this.ruleForm.invoiceMoney.toFixed(2);
        this.addListDataLoadin = false;
      }
    },
    // 开票明细
    bkpList(row,index){
      this.kpData = JSON.parse(JSON.stringify(row.projectList));
      this.kpIndex = index
      this.bgkpVisible = true
    },
    // 查看明细时 - 开票金额<=应收金额-已开票金额
    listenIn(row) {
      let str = "";
      if(Number(this.zF.oninput2(row.invoiceFee, 2))>Number((Number(row.amountReceivable) - Number(row.invoiceFeeDone)).toFixed(2))){
        str = (Number(row.amountReceivable) - Number(row.invoiceFeeDone)).toFixed(2);
      } else {
        str = this.zF.oninput2(row.invoiceFee, 2);
      }
      return str;
    },
    // 关闭回调
    doClose() {
      this.kpData = []
      this.kpIndex = 0
      this.bgkpVisible = false
      console.log(this.ruleForm.billNodeList);
    },
    // 开票明细 - 确定
    kpDoOk(){
      this.ruleForm.billNodeList[this.kpIndex].projectList = this.kpData
      this.ruleForm.billNodeList[this.kpIndex].currentMoney = this.kpData.reduce((a, b) => {
        a += (isNaN(+b.invoiceFee)?0:+b.invoiceFee);
        return a;
      }, 0);
      this.ruleForm.billNodeList[this.kpIndex].currentMoney = this.ruleForm.billNodeList[this.kpIndex].currentMoney.toFixed(2);
      this.currentMoneyChange();
      this.doClose()
    },
    // 选择结算单后获取开票信息及开票金额
    projectDetailList(billId){
      return this.$post("/biz/new/bill/projectDetailList", {billId}).then((res) => {
        return res.data
      })
    },
    // 结算单列表 - 删除
    deleteInvoicing(row, index) {
      if (row.invoiceBillId) {
        this.$post("/biz/new/bill/invoice/delBill", {
          invoiceBillId: row.invoiceBillId,
        }).then((res) => {
          if (res.status == 0) {
            this.ruleForm.billNodeList.splice(index, 1);
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.ruleForm.invoiceMoney = 0;
            this.ruleForm.billNodeList.map((el) => {
              this.ruleForm.invoiceMoney += Number(el.currentMoney);
            });
          }
        });
      } else {
        this.ruleForm.billNodeList.splice(index, 1);
        this.ruleForm.invoiceMoney = 0;
        this.ruleForm.billNodeList.map((el) => {
          this.ruleForm.invoiceMoney += Number(el.currentMoney);
        });
      }
    },
    // 取消/确认
    completez(type) {
      if (type == 1) {
        if (this.stu == "billState") {
          this.$router.push({
            path: "/web/financialSettlement/BillStatementList",
            query: {
              refresh: true,
            },
          });
        } else if (this.stu == "settlement") {
          this.$router.push({
            path: "/web/settlementManagement",
            query: {
              refresh: true,
            },
          });
        } else {
          this.$router.push({
            path: "/web/financialSettlement/invoicingManagement",
            query: {
              refresh: true,
            },
          });
        }
      } else {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            const params = {
              ...this.ruleForm,
            };
            // 有id编辑;无id添加
            if (this.id) {
              params.invoiceId = this.id;
            }
            // 判断有无 - 收票单位id;没有-添加收票单位(后台处理)
            if (params.closeCompany == params.closeCompanyId) {
              delete params.closeCompanyId;
            }
            this.$post(
              this.stu != "settlement"
                ? "/biz/new/bill/invoice/saveOrUpdate"
                : "/biz/comp/apply/invoice/insert",
              params
            ).then((res) => {
              if (res.status == 0) {
                if (this.stu == "settlement") {
                  this.$message({
                    type: "success",
                    message: "申请开票成功",
                  });
                  this.$router.push({
                    path: "/web/settlementManagement",
                    query: {
                      refresh: true,
                    },
                  });
                } else if (this.stu == "billState") {
                  this.$message({
                    type: "success",
                    message: this.id ? "编辑成功" : "申请开票成功",
                  });
                  this.$router.push({
                    path: "/web/financialSettlement/BillStatementList",
                    query: {
                      refresh: true,
                    },
                  });
                } else {
                  this.$message({
                    type: "success",
                    message: this.id ? "编辑成功" : "新增成功",
                  });
                  this.$router.push({
                    path: "/web/financialSettlement/invoicingManagement",
                    query: {
                      refresh: true,
                    },
                  });
                }
              }
            });
          }
        });
      }
    },
    //开票金额change
    currentMoneyChange() {
      this.ruleForm.invoiceMoney = 0;
      this.ruleForm.billNodeList.map((el) => {
        this.ruleForm.invoiceMoney += Number(el.currentMoney);
      });
      this.ruleForm.invoiceMoney = this.ruleForm.invoiceMoney.toFixed(2);
    },
    // 回显 - 收票单位
    getCompanyName(id) {
      this.$post("/biz/new/bill/invoice/company/getByName", {
        closeCompany: id,
      }).then((res) => {
        if (!res.data.closeCompanyId) {
          res.data.closeCompanyId = res.data.closeCompany;
        }
        this.CompanyList = [res.data];
        console.log(this.CompanyList);
        this.ruleForm.closeCompany = res.data.closeCompany || "";
        this.ruleForm.closeCompanyId = res.data.closeCompanyId || "";
        //税号

        this.ruleForm.taxNo = res.data.taxNo || "";
        // 开户行及账号
        this.ruleForm.accountNumber = res.data.accountNumber || "";
        // 地址、电话
        this.ruleForm.addressMobile = res.data.addressMobile || "";
        // 邮箱
        this.ruleForm.email = res.data.email || "";
      });
    },
    // 回显 - 收票单位(机构)
    getCompanyJg(id) {
      this.$post("/sys/company/id", {
        compId: id,
      }).then((res) => {
        this.ruleForm.closeCompanyId = res.data.compName;
        this.getCompanyName(res.data.compName);
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.ovy-a /deep/ .el-table__body-wrapper {
  min-height: 220px;
}
.zl {
  text-align: left;
}
.r {
  text-align: right;
}
.basicData {
  width: 530px;
}
.BatchInvoicingManagementAddEdit {
  padding: 1rem 1rem;
  width: 100%;
  .BatchBox {
    display: flex;
    height: 70px;
    border: 1px solid #ebeef5;
    border-radius: 5px;
    .BatchBoxFirst {
      width: 260px;
      background: #f2f7fd;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .BatchBoxSecond {
      width: 100%;
      .BatchforBox {
        display: flex;
        flex-wrap: wrap;
        .BatchforFlex {
          display: flex;
          align-items: center;
          padding: 5px 20px;
          cursor: pointer;
          .BatchImgBox {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
.btn-box {
  padding: 10px 0;
  width: 131px;
  margin: auto;
}
/deep/ .txtRight .el-input__inner{
  text-align: right;
}
</style>
